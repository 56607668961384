import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPrivate } from "../../../shared/api/axios-wrapper.service";
import { getConfig } from "../../../config/config";

export const requestFetchAllContactsAsync = createAsyncThunk(
  "messages/requestFetchAllContactsAsync",
  async (requestPayload: any) => {
    const response = await axiosPrivate.get(
      `${getConfig().messagingBase}/api/v1/Messaging/ContactList`,
      {
        params: {
          userId: requestPayload.userId,
          patientId: requestPayload.patientId,
        },
      }
    );
    return response.data;
  }
);

export const requestGetMessageLogsAsync = createAsyncThunk(
  "messages/requestGetMessageLogsAsync",
  async (requestPayload: any, thunkApi) => {
    const response = await axiosPrivate.post(
      `${getConfig().messagingBase}/api/v1/Messaging/GetConversationList`,
      {
        userID: requestPayload.userId,
        limit: requestPayload.limit,
        offset: requestPayload.offset,
        searchKeyword: requestPayload.searchKeyword,
        sortOrder: requestPayload.sortOrder,
        sortColumn: requestPayload.sortColumn,
      },
      { signal: thunkApi.signal }
    );
    if (response.data.conversationDetails) {
      return response.data;
    } else {
      thunkApi.rejectWithValue("No messages found");
    }
  }
);

export const requestcreateConversationAsync = createAsyncThunk(
  "messages/requestcreateConversationAsync",
  async (
    requestPayload: {
      ownerUserId?: string;
      participantsUserId: Array<string>;
      subject?: string;
      patientId?: number | null;
      type?: string;
      tags?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.post(
        `${getConfig().messagingBase}/api/v1/Messaging/CreateConversation`,
        requestPayload
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response);
    }
  }
);

export const requestfetchAllConversationMessagesAsync = createAsyncThunk(
  "messages/requestfetchAllConversationMessagesAsync",
  async (requestPayload: { conversationId: number; offset: number }) => {
    const response = await axiosPrivate.post(
      `${getConfig().messagingBase}/api/v1/Messaging/GetConversationMessage`,
      {
        conversationID: requestPayload.conversationId,
        limit: 10,
        offset: requestPayload.offset,
      }
    );
    return response.data;
  }
);

export const requestSendMessageAsync = createAsyncThunk(
  "messages/requestSendMessageAwsync",
  async (
    requestPayload: {
      conversationId: number;
      conversationTwilioId: string;
      message: string | null;
      messageType: string;
      messageOwnerEmail: string;
      messageOwnerUserId: string;
      fileUpload: File | null;
      onUpload: (progress: ProgressEvent) => void;
      abortController?: AbortController;
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        onUploadProgress: (progress: any) => {
          requestPayload.onUpload(progress);
        },
        signal: requestPayload.abortController?.signal,
        headers: {
          "Ocp-Apim-Subscription-Key": getConfig().subscriptionKey!,
          "Content-Type": "multipart/form-data",
        },
      };

      const formData = new FormData();
      Object.entries<any>({
        conversationID: requestPayload.conversationId,
        conversationTwilioID: requestPayload.conversationTwilioId,
        messageBody: requestPayload.message,
        messageType: requestPayload.messageType,
        messageOwnerEmailAddress: requestPayload.messageOwnerEmail,
        messageOwnerUserID: requestPayload.messageOwnerUserId,
        fileUpload: requestPayload.fileUpload,
      }).forEach((item) => {
        if (item[0] === "fileUpload" && requestPayload.fileUpload) {
          formData.append("fileUpload", requestPayload.fileUpload);
        } else {
          formData.append(item[0], item[1]);
        }
      });

      const response = await axiosPrivate.put(
        `${getConfig().messagingBase}/api/v1/Messaging/sendMessage`,
        formData,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log("error in send message", error);

      return rejectWithValue(error?.response);
    }
  }
);

export const requestGetAllConversationsAsync = createAsyncThunk(
  "messages/requestGetAllConversationsAsync",
  async (requestPayload: {
    userID: string;
    limit: number;
    offset: number;
    searchKeyword: string;
    convStartDate: string;
    convEndDate: string;
  }) => {
    const response = await axiosPrivate.post(
      `${getConfig().messagingBase}/api/v1/Messaging/GetConversationList`,
      requestPayload
    );
    return response.data;
  }
);

export const requestGetMessageCountAsync = createAsyncThunk(
  "messages/requestGetMessageCountAsync",
  async (conversationId: string) => {
    const response = await axiosPrivate.get(
      `${getConfig().messagingBase}/api/v1/Messaging/GetMessageCount`,
      {
        params: {
          conversationID: conversationId,
        },
      }
    );
    return response.data;
  }
);

export const requestGetConversationIfPresentAsync = createAsyncThunk(
  "messages/requestGetConversationIfPresentAsync",
  async (requestPayload: {
    ownerUserId?: string;
    participantsUserId: Array<string>;
    subject?: string;
    patientId?: number | null;
    type?: string;
    tags?: string;
  }) => {
    const response = await axiosPrivate.post(
      `${getConfig().messagingBase}/api/v1/Messaging/CheckExistingConversation`,
      requestPayload
    );
    return response.data;
  }
);

export const getPatientDetailsForConversationAsync = createAsyncThunk(
  "messaging/getPatientDetailsForConversationAsync",
  async (
    requestPayload: { conversationId: number; detailedInfo?: boolean },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.get(
        `${
          getConfig().messagingBase
        }/api/v1/Messaging/patient-detail?conversationId=${
          requestPayload.conversationId
        }&detailedInfo=${!!requestPayload.detailedInfo}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getVacationStatusAsync = createAsyncThunk(
  "messaging/getVacationStatusAsync",
  async (
    { conversationId }: { conversationId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.get(
        `${
          getConfig().messagingBase
        }/api/v1/Messaging/vacation-status/${conversationId}`
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
